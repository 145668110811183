import { gantt } from 'dhtmlx-gantt';
import { Mixin } from 'leaflet';

Mixin;
export default {
  data: () => {
    return {
      zoomConfig: {
        levels: [
          {
            name: 'hour',
            scale_height: 53,
            min_column_width: 50,
            scales: [
              { unit: 'day', format: '%F %d' },
              { unit: 'hour', step: 1, format: '%H:%i' },
            ],
          },
          {
            name: 'day',
            scale_height: 27,
            min_column_width: 80,
            scales: [{ unit: 'day', step: 1, format: '%d %M' }],
          },
          {
            name: 'week',
            scale_height: 50,
            min_column_width: 60,
            scales: [
              {
                unit: 'week',
                step: 1,
                format: function (date) {
                  const dateToStr = gantt.date.date_to_str('%d %M');
                  const endDate = gantt.date.add(date, -6, 'day');
                  const weekNum = gantt.date.date_to_str('%W')(date);
                  return (
                    '#' +
                    weekNum +
                    ', ' +
                    dateToStr(date) +
                    ' - ' +
                    dateToStr(endDate)
                  );
                },
              },
              { unit: 'day', step: 1, format: '%j %D' },
            ],
          },
          {
            name: 'month',
            scale_height: 50,
            min_column_width: 120,
            scales: [
              { unit: 'month', format: '%F, %Y' },
              { unit: 'week', format: 'Week #%W' },
            ],
          },
          {
            name: 'quarter',
            height: 50,
            min_column_width: 90,
            scales: [
              { unit: 'month', step: 1, format: '%M' },
              {
                unit: 'quarter',
                step: 1,
                format: function (date) {
                  const dateToStr = gantt.date.date_to_str('%M');
                  const endDate = gantt.date.add(
                    gantt.date.add(date, 3, 'month'),
                    -1,
                    'day'
                  );
                  return dateToStr(date) + ' - ' + dateToStr(endDate);
                },
              },
            ],
          },
          {
            name: 'year',
            scale_height: 50,
            min_column_width: 30,
            scales: [{ unit: 'year', step: 1, format: '%Y' }],
          },
        ],
      },
    };
  },
};
